"use client"
import { gql } from "graphql-request";
import { useFilminfo } from "src/lib/client/useFilminfo";
import { Maybe, WatchableContentType } from "src/lib/movieinfo/movieinfotypes";
import { useDebugValue, useEffect, useRef, useState } from "react";
import { QueryFunction, useQuery } from "@tanstack/react-query";
import axios, { isAxiosError } from "axios";
import { ISearchResult } from "src/lib/types/search";
import { useExperimentalSearchContext } from "src/lib/search/ExperimentalContext";

interface ITypedSearchResult extends ISearchResult {
    __typename: "ISearchResult";
}

function customSortExp2(data: ITypedSearchResult[] | undefined): ITypedSearchResult[] | undefined {
    /** Fra Nils på slack
     * Definisjoner:

        p = TMDB popularity
        f = popularitetsfaktor

        Mål:
        f skal ligge mellom 2 og 0,5.
        Alt skal normaliseres rundt p = 20, dvs at når p = 20 skal f = 1

        Maks popularitet vi tar hensyn til skal være 150, norske titler
        må skaleres opp.

        if (norsk) p = p*10
        if (p > 150) p = 150
        if (p >= 20)
        {
            f = ((p - 20) / 130) + 1
        }
        else
        {
            f = 1 - (20 - p) / 40
        }

        Sorteringen av treff kan nå gjøres på (score * f)
     */
    if (!data) {
        return data;
    }
    console.debug("Using customSortExp2");

    const newScore = data.map(sr => {
        if (sr.document.popularity) {
            let f = 0;
            // norwegian titles are already boosted in the index
            const p = Math.min(sr.document.popularity, 150);
            if (p >= 20) {
                f = ((p - 20) / 130) + 1;
            } else {
                f = 1 - (20 - p) / 40;
            }

            return { ...sr, score: sr.score * f, rawScore: sr.score };
        }
        return sr;
    });
    return newScore.sort((a, b) => b.score - a.score);

}

type AzUseSearchOptions = {
    streamingOnly?: boolean;
    maxNumItems?: number;
    inputDelay?: number;
    active?: boolean;
    quickSearch?: boolean;
    fuzzyWeight?: number;
    customSortNum?: number;
}



export function useAzSearch(searchText?: string, opts?: AzUseSearchOptions) {
    const { streamingOnly = false, maxNumItems, inputDelay = 400, active = true, quickSearch = false, fuzzyWeight, customSortNum } = opts ?? {};
    const expSearchContext = useExperimentalSearchContext();
    const _quickSearch = quickSearch || expSearchContext.qs === "1";
    /*useDebugValue(searchText, searchText => `searchText: ${searchText}`);
    useDebugValue(streamingOnly, streamingOnly => `streamingOnly: ${streamingOnly}`);
    useDebugValue(maxNumItems, maxNumItems => `maxNumItems: ${maxNumItems}`);
    useDebugValue(inputDelay, inputDelay => `inputDelay: ${inputDelay}`);
    useDebugValue(active, active => `active: ${active}`);
    useDebugValue(expSearchContext.sp, sp => `Scoring Profile: ${sp}`);
    useDebugValue(expSearchContext.qt, qt => `Query type: ${qt}`);
    useDebugValue(expSearchContext.sm, sm => `Search mode: ${sm}`);
    useDebugValue(expSearchContext.qst, qst => `Query string type: ${qst}`);*/

    const [_searchText, setSearchText] = useState("");

    const { data, isFetched, isFetching } = useQuery<ITypedSearchResult[]>({
        queryKey: ["azsearch", _searchText?.trim(), streamingOnly, maxNumItems, _quickSearch, expSearchContext.sp, expSearchContext.qt, expSearchContext.sm, expSearchContext.qst, fuzzyWeight ?? expSearchContext.qsw.f, expSearchContext.qsw.p, expSearchContext.qsw.e, customSortNum],
        enabled: active && !!_searchText?.trim(),
        queryFn: azQFn,
        refetchOnWindowFocus: false,

    });
    const searchTimer = useRef<any>(null);

    useEffect(() => {
        // make sure a new search is not triggered until the input delay has fired
        if (searchText?.trim() && searchText.trim() !== _searchText) {
            if (searchTimer.current) {
                clearTimeout(searchTimer.current);
                searchTimer.current = null;
            }
            if (inputDelay > 0) {
                searchTimer.current = setTimeout(() => {
                    setSearchText(searchText.trim());
                }, inputDelay);
            } else {
                setSearchText(searchText.trim());
            }
        } else if (!searchText?.trim()) {
            if (searchTimer.current) {
                clearTimeout(searchTimer.current);
                searchTimer.current = null;
            }
            setSearchText("");
        }
        return () => {
            if (searchTimer.current) {
                clearTimeout(searchTimer.current);
                searchTimer.current = null;
            }
        }
    }, [searchText, _searchText, inputDelay]);

    return {
        searchLoading: isFetching,
        searchLoaded: isFetched,
        searchResult: data
    };
}

const azQFn: QueryFunction<ITypedSearchResult[]> = async (context) => {
    const { signal } = context;
    const searchTerm = context.queryKey[1];
    const streamingOnly = context.queryKey[2] as boolean;
    const maxNumItems = context.queryKey[3] as number | undefined;
    const quickSearch = context.queryKey[4] as boolean;
    const scoringProfile = context.queryKey[5];
    const queryType = context.queryKey[6];
    const searchMode = context.queryKey[7];
    const queryStringType = context.queryKey[8];
    const queryStringWf = context.queryKey[9];
    const queryStringWp = context.queryKey[10];
    const queryStringWe = context.queryKey[11];
    const customSort = context.queryKey[12] as number | undefined;

    const params: any = {
        so: streamingOnly ? "1" : "0",
        sp: scoringProfile,
        qt: queryType,
        sm: searchMode,
        qst: queryStringType,
        q: searchTerm,
        qswf: queryStringWf,
        qswp: queryStringWp,
        qswe: queryStringWe,
        qs: quickSearch ? "1" : "0"
    };

    if (maxNumItems) {
        params.max = maxNumItems;
    }

    const axiosResponse = await axios.request<ISearchResult[]>({
        url: process.env.NEXT_PUBLIC_SEARCH_ENDPOINT, method: "GET", params, signal
    });
    if (isAxiosError(axiosResponse)) {
        throw axiosResponse;
    }
    let result = axiosResponse.data.map(d => ({ ...d, __typename: "ISearchResult" }) as ITypedSearchResult);
    switch (customSort) {
        case 2:
            result = customSortExp2(result)!;
            break;
    }

    return result;
}

type UseSearchOptions = {
    streamingOnly?: boolean;
    maxNumItems?: number;
    includeLocations?: boolean;
    inputDelay?: number;
    active?: boolean;
    locationOnly?: boolean;
}

export default function useSearch(searchText?: string, opts?: UseSearchOptions) {
    const { streamingOnly = false, maxNumItems = 10, includeLocations = true, locationOnly = false, inputDelay = 400, active = true } = opts ?? {};

    useDebugValue(searchText, searchText => `searchText: ${searchText}`);
    useDebugValue(streamingOnly, streamingOnly => `streamingOnly: ${streamingOnly}`);
    useDebugValue(maxNumItems, maxNumItems => `maxNumItems: ${maxNumItems}`);
    useDebugValue(includeLocations, includeLocations => `includeLocations: ${includeLocations}`);
    useDebugValue(locationOnly, locationOnly => `locationOnly: ${locationOnly}`);
    useDebugValue(inputDelay, inputDelay => `inputDelay: ${inputDelay}`);
    useDebugValue(active, active => `active: ${active}`);

    const query = locationOnly ? SEARCH_QUERY_LOC_ONLY : (includeLocations ? SEARCH_QUERY_WITH_LOC : SEARCH_QUERY);

    const [_searchText, setSearchText] = useState("");
    const { fiLoading, fiLoaded, fiData } = useFilminfo(query, { searchText: _searchText?.trim(), streamingOnly, maxNumItems }, { active: active && !!_searchText?.trim() });
    const searchTimer = useRef<any>(null);

    useEffect(() => {
        // make sure a new search is not triggered until the input delay has fired
        if (searchText?.trim() && searchText.trim() !== _searchText) {
            if (searchTimer.current) {
                clearTimeout(searchTimer.current);
                searchTimer.current = null;
            }
            if (inputDelay > 0) {
                searchTimer.current = setTimeout(() => {
                    setSearchText(searchText.trim());
                }, inputDelay);
            } else {
                setSearchText(searchText.trim());
            }
        } else if (!searchText?.trim()) {
            if (searchTimer.current) {
                clearTimeout(searchTimer.current);
                searchTimer.current = null;
            }
            setSearchText("");
        }
        return () => {
            if (searchTimer.current) {
                clearTimeout(searchTimer.current);
                searchTimer.current = null;
            }
        }
    }, [searchText, _searchText, inputDelay]);

    return {
        searchLoading: fiLoading,
        searchLoaded: fiLoaded,
        searchResult: (fiData?.searchForWatchableContent?.filter(resultFilter) || []) as WatchableContentType[],
        locationResult: includeLocations || locationOnly ? fiData?.cinemaQuery?.searchForLocations?.map(l => l!.name) || [] : null
    };
}

function resultFilter(watchable: Maybe<WatchableContentType>) {
    return watchable?.movieId || watchable?.streamingContentId;
}

const SEARCH_QUERY_WITH_LOC = gql`
query searchQuery($searchText: String, $streamingOnly: Boolean, $maxNumItems: Int) {
	searchForWatchableContent(searchText: $searchText, streamingOnly: $streamingOnly, maxNumItems: $maxNumItems) {
		__typename
		movieId
		streamingContentId
		title
		productionYear
		isCinemaRelevant

		isSeries
		sanityImageWideUrl
        sanityImagePosterUrl
		imageLandscapeStreaming
		imagesLandscapeStreaming {
			__typename
			height
			width
			url
		}
		imagePosterStreaming
		imagesPosterStreaming {
			__typename
			width
			height
			url
		}
	}
	cinemaQuery {
		searchForLocations(searchText: $searchText) {
			name
		}
	}
}
  `;

const SEARCH_QUERY_LOC_ONLY = gql`
query searchQuery($searchText: String) {
	cinemaQuery {
		searchForLocations(searchText: $searchText) {
			name
		}
	}
}
  `;

const SEARCH_QUERY = gql`query searchQuery($searchText: String, $streamingOnly: Boolean, $maxNumItems: Int) {
	searchForWatchableContent(searchText: $searchText, streamingOnly: $streamingOnly, maxNumItems: $maxNumItems) {
		__typename
		movieId
		streamingContentId
		title
		productionYear
		isCinemaRelevant

		isSeries
		imagesLandscapeCinema {
		  format
		  height
		  width
		  url
		}
		imagesPosterCinema {
		  width
		  height
		  url
		}
		imageLandscapeStreaming
		imagesLandscapeStreaming {
		  height
		  width
		  url
		}
		imagePosterStreaming
		imagesPosterStreaming {
		  width
		  height
		  url
		}
	}
  }

  `;
