import FilmwebLink from 'src/components/filmweblink/FilmwebLink';
import { CmsImageForUrl } from "src/components/images/CmsImageForUrl";
import ExternalImageForUrl from "src/components/images/ExternalImageForUrl";
import Image from 'src/components/images/Image';
import CSSPoster, { CSSPosterMissing, CSSPosterSizer, CSSPosterWrapper, SPosterMissingProps } from 'src/components/posterrow/CSSPoster';
import { SRowProps } from 'src/components/search/SRowProps';
import theme from "src/components/themes/DefaultTheme";
import { getContentUrl, getContentUrlAzSearch } from 'src/lib/movieinfo/getContentUrl';
import { ImageVersionType, WatchableContentType } from 'src/lib/movieinfo/movieinfotypes';
import styled, { css } from "src/lib/styles/css";
import { ISearchResult } from "src/lib/types/search";
import { createUrl } from "src/components/filmweblink/FilmwebLink";
import { useState } from "react";
import { useExperimentalSearchContext } from "src/lib/search/ExperimentalContext";


//#region [Props]
type ResultPosterProps = {
    data: WatchableContentType;

    forceStreaming?: boolean;
    mobileLayout?: "row" | "column";
    clickTracker?: (id: string | number, title: string) => void;
    className?: string;
};
//#endregion

//#region [Component]
export default function ResultPoster({ data, className, clickTracker, mobileLayout = "column", forceStreaming = false }: ResultPosterProps) {
    const hasSanityPoster = !!data.sanityImagePosterUrl;
    const hasStreamingPosterArray = !hasSanityPoster && (data.imagesPosterStreaming?.length ?? 0) > 0;
    const hasStreamingPosterUrl = !hasStreamingPosterArray && data.imagePosterStreaming;
    const hasPoster = hasSanityPoster || hasStreamingPosterArray || hasStreamingPosterUrl;

    const url = getContentUrl(data, forceStreaming);

    return (<SArticle $isRowLayout={mobileLayout === "row"} className={className}>
        <SPosterWrapper>
            <SImageSizer $isPosterMissing={!hasPoster} to={url} onClick={() => clickTracker?.(data.streamingContentId, data.title)} data-postermissingtext={data.title}>
                {hasPoster && <>
                    {hasSanityPoster && <CmsImageForUrl
                        imageUrl={data.sanityImagePosterUrl}
                        alt={`Poster for '${data.title}'`}
                        unoptimized
                        width={270} />}
                    {hasStreamingPosterArray && <Image
                        alt={`Poster for '${data.title}'`}
                        image={data.imagesPosterStreaming as ImageVersionType[]}
                        sizes="270xp" />}
                    {hasStreamingPosterUrl && <ExternalImageForUrl
                        imageUrl={data.imagePosterStreaming}
                        alt={`Poster for '${data.title}'`} />}
                </>}
            </SImageSizer>
        </SPosterWrapper>
        <SPosterHeader>
            <FilmwebLink onClick={() => clickTracker?.(data.streamingContentId, data.title)} to={url}>
                <h1>{data.title}</h1>
                {data.productionYear && data.productionYear !== "0" && data.productionYear !== "-1" && <p>{data.productionYear}</p>}
            </FilmwebLink>
        </SPosterHeader>
    </SArticle >);
}
//#endregion

//#region [Props]
type AzResultPosterProps = {
    data: ISearchResult;
    forceStreaming?: boolean;
    mobileLayout?: "row" | "column";
    clickTracker?: (id: string | number, title: string) => void;
    className?: string;
    showScore?: boolean;
};
//#endregion

//#region [Component]
export function AzResultPoster({ data, className, clickTracker, mobileLayout = "column", forceStreaming = false, showScore = false }: AzResultPosterProps) {
    const { document } = data;
    const hasSanityPoster = !!document.posterUrl;
    const hasStreamingPosterUrl = !!document.streamingPosterUrl;
    const hasPoster = hasSanityPoster || hasStreamingPosterUrl;
    const url = getContentUrlAzSearch(document, forceStreaming);
    const expContext = useExperimentalSearchContext();


    return (<SArticle $isRowLayout={mobileLayout === "row"} className={className} onMouseDown={e => { if (e.button === 1) expContext.setOverlayData(data) }}>
        <SPosterWrapper>
            <SImageSizer $isPosterMissing={!hasPoster} to={url} onClick={() => clickTracker?.((document.streamingId ?? document.edi)!, document.title)} data-postermissingtext={document.title}>
                {showScore && <ScoreData searchResult={data} />}
                {hasPoster && <>
                    {hasSanityPoster && <CmsImageForUrl
                        imageUrl={document.posterUrl!}
                        alt={`Poster for '${document.title}'`}
                        unoptimized
                        width={270} />}
                    {hasStreamingPosterUrl && <ExternalImageForUrl
                        imageUrl={document.streamingPosterUrl?.startsWith("/") ? `https://image.tmdb.org/t/p/w780${document.streamingPosterUrl!}` : document.streamingPosterUrl!}
                        alt={`Poster for '${document.title}'`} />}
                </>}
            </SImageSizer>
        </SPosterWrapper>
        <SPosterHeader>
            <FilmwebLink onClick={() => clickTracker?.((document.streamingId ?? document.edi)!, document.title)} to={url}>
                <h1>{document.title}</h1>
                {document.yearCinema && document.yearCinema !== "0" && document.yearCinema !== "-1" && <p>{document.yearCinema}</p>}
            </FilmwebLink>
        </SPosterHeader>
    </SArticle>);
}
//#endregion

function ScoreData({ searchResult }: { searchResult: ISearchResult }) {
    return <SScoreData>
        <div>sc: {searchResult.score}</div>
        <div>pop: {searchResult.document.popularity?.toFixed(3)}</div>
        <div>rawsc: {searchResult.rawScore ?? searchResult.score}</div>
    </SScoreData>
}


//#region [Styles]
const SSearchDataOverlay = styled.pre`
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 100;
    font-size: 0.8rem;
`;


const SArticle = styled.article<SRowProps>`
	${CSSPoster}

	${props => props.$isRowLayout && css`
		@media ${theme.mq.mobile} {
			display: flex;
			width: 100%;
		}
	`}

	img {
		display: block;
		width: 100%;
	}
`;

const SPosterWrapper = styled.div<SRowProps>`
	${CSSPosterWrapper}

	${props => props.$isRowLayout && css`
		@media ${theme.mq.mobile} {
			width: 30%;
		}
	`}
`;


const SImageSizer = styled(FilmwebLink) <SPosterMissingProps>`
	${CSSPosterSizer}

	${props => props.$isPosterMissing && CSSPosterMissing}
`;

const SScoreData = styled.div`
    background-color: white;
    color: black;
    padding: 0.1em;
    font-size: 0.8rem;
`;

const SPosterHeader = styled.header`
	line-height: 1.1;
	h1,
	p {
		font-size: 0.8rem;
	}

	p {
		font-weight: bold;
		text-align: center;
		margin: 0;
	}

	h1 {
		margin: 0.5em 0 0 0 !important;
	}
`;

//#endregion